import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children }) {
  const theme = useTheme();
  const upMd = useResponsive('up', 'md');

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default'
      }}
    >
      <Stack
        sx={{
          width: 1,
          mx: 'auto',
          maxWidth: 480,
          px: { xs: 2, md: 8 },
          py: { xs: 5, md: 8 }
        }}
      >
        {children}
      </Stack>
    </Box>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node
};
