import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || error)
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  project: {
    list: '/api/projects',
    details: '/api/projects',
    new: '/api/projects',
    update: '/api/projects',
    search: '/api/projects/search',
    start: '/api/projects/start',
    variant: {
      search: '/api/projects/variants/search',
      detail: '/api/projects/variants',
      new: '/api/projects',
      update: '/api/projects',
      types: '/api/project/values/variant-type',
      stageAttributeTemplates: 'api/project/Values/stage-attribute-template',
      stageSubmission: {
        attributes: {
          save: '/api/projects/variants/submissions' // submissionId/save-attributes
        },
        files: {
          upload: '/api/projects/variants/submissions' // submissionId/add-file
        },
        comment: {
          add: '/api/projects/variants/submissions' // submissionId/add-comment
        },
        actions: '/api/projects/variants/submissions' // submissionId/ various actions
      }
    }
  },
  material: {
    list: '/api/material',
    new: '/api/material',
    search: '/api/material/search'
  },
  catalog: {
    list: '/api/catalogItem',
    new: '/api/catalogItem',
    search: '/api/catalogItem/search'
  },
  customer: {
    list: '/api/customers',
    new: '/api/customers',
    search: '/api/customers/search',
    update: '/api/customers'
  },
  order: {
    list: '/api/orders',
    new: '/api/orders',
    search: '/api/orders/search',
    sendToProduction: '/api/orders/send-to-production',
    cancel: '/api/orders/cancel',
    details: '/api/orders',
    taskHistory: '/api/orders/task-history'
  },
  user: {
    list: '/api/users',
    details: '/api/users',
    update: '/api/users',
    create: '/api/users',
    search: '/api/users/search'
  },
  workorder: {
    list: '/api/workorders',
    startProcess: '/api/workorders/start-process',
    pauseProcess: '/api/workorders/pause-process',
    completeProcess: '/api/workorders/complete-process',
    cancelProcess: '/api/workorders/cancel-process'
  },
  taskQueue: {
    list: 'api/TaskQueue/get-open-manual',
    claim: (id) => `/api/TaskQueue/${id}/claim`,
    release: (id) => `/api/TaskQueue/${id}/release`,
    save: 'api/TaskQueue/save',
    file: 'api/Files'
  },
  inventory: {
    constants: '/api/Inventory/constants'
  },
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register'
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels'
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search'
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search'
  }
};
