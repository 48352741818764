import { m } from 'framer-motion';
import { useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const popover = usePopover();

  const handleClick = useCallback(() => {
    // Handle language change
  }, []);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 36,
          height: 36,
          ...(popover.open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08)
          })
        }}
      >
        <Iconify icon="flagpack:tr" width={22} sx={{ borderRadius: 0.65 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        <MenuItem onClick={handleClick} sx={{ py: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Iconify icon="flagpack:tr" width={28} sx={{ borderRadius: 0.65 }} />
            Türkçe
          </Stack>
        </MenuItem>
      </CustomPopover>
    </>
  );
}
