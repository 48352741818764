// SOL MENU

import { useMemo } from 'react';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const ICONS = {
  // Overview
  dashboard: <Iconify icon="solar:chart-square-bold-duotone" sx={{ color: '#00B8D9' }} />,
  app: <Iconify icon="solar:home-2-bold-duotone" sx={{ color: '#00B8D9' }} />,

  // Production
  production: <Iconify icon="solar:factory-bold-duotone" sx={{ color: '#36B37E' }} />,
  project: <Iconify icon="solar:folder-with-files-bold-duotone" sx={{ color: '#36B37E' }} />,
  projectList: (
    <Iconify icon="solar:documents-minimalistic-bold-duotone" sx={{ color: '#36B37E' }} />
  ),
  projectNew: <Iconify icon="solar:document-add-bold-duotone" sx={{ color: '#36B37E' }} />,
  workorder: <Iconify icon="solar:checklist-minimalistic-bold-duotone" sx={{ color: '#36B37E' }} />,
  tasks: <Iconify icon="solar:list-check-bold-duotone" sx={{ color: '#36B37E' }} />,

  // Catalog
  catalog: <Iconify icon="solar:box-minimalistic-bold-duotone" sx={{ color: '#FF5630' }} />,
  catalogManagement: <Iconify icon="solar:widget-add-bold-duotone" sx={{ color: '#FF5630' }} />,
  catalogList: <Iconify icon="solar:widget-bold-duotone" sx={{ color: '#FF5630' }} />,
  catalogNew: <Iconify icon="solar:add-square-bold-duotone" sx={{ color: '#FF5630' }} />,

  // Orders & Sales
  sales: <Iconify icon="solar:shop-bold-duotone" sx={{ color: '#FFAB00' }} />,
  order: <Iconify icon="solar:cart-large-minimalistic-bold-duotone" sx={{ color: '#FFAB00' }} />,
  orderManagement: <Iconify icon="solar:cart-check-bold-duotone" sx={{ color: '#FFAB00' }} />,
  orderList: <Iconify icon="solar:document-text-bold-duotone" sx={{ color: '#FFAB00' }} />,
  orderNew: <Iconify icon="solar:file-add-bold-duotone" sx={{ color: '#FFAB00' }} />,

  // Users & Customers
  management: <Iconify icon="solar:settings-bold-duotone" sx={{ color: '#7C5DFA' }} />,
  user: <Iconify icon="solar:users-group-two-bold-duotone" sx={{ color: '#7C5DFA' }} />,
  userManagement: (
    <Iconify icon="solar:users-group-rounded-bold-duotone" sx={{ color: '#7C5DFA' }} />
  ),
  userList: <Iconify icon="solar:users-group-rounded-bold-duotone" sx={{ color: '#7C5DFA' }} />,
  userNew: <Iconify icon="solar:user-plus-rounded-bold-duotone" sx={{ color: '#7C5DFA' }} />,
  customer: <Iconify icon="solar:user-id-bold-duotone" sx={{ color: '#7C5DFA' }} />,
  customerManagement: (
    <Iconify icon="solar:users-group-two-rounded-bold-duotone" sx={{ color: '#7C5DFA' }} />
  ),
  customerList: (
    <Iconify icon="solar:users-group-two-rounded-bold-duotone" sx={{ color: '#7C5DFA' }} />
  ),
  customerNew: <Iconify icon="solar:user-plus-bold-duotone" sx={{ color: '#7C5DFA' }} />
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // Overview
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.app
          }
        ]
      },
      // Production
      {
        subheader: t('production'),
        icon: ICONS.production,
        items: [
          {
            title: t('project-management'),
            path: paths.production.projectManagement.root,
            icon: ICONS.project,
            children: [
              {
                title: t('project-list'),
                path: paths.production.projectManagement.root,
                icon: ICONS.projectList
              },
              {
                title: t('project-new'),
                path: paths.production.projectManagement.create,
                icon: ICONS.projectNew
              }
            ]
          },
          {
            title: 'Görevler',
            path: paths.taskQueue.root,
            icon: ICONS.tasks
          }
        ]
      },
      // Orders & Sales
      {
        subheader: 'Satış',
        icon: ICONS.sales,
        items: [
          {
            title: t('order-management'),
            path: paths.order.root,
            icon: ICONS.orderManagement,
            children: [
              {
                title: t('order-list'),
                path: paths.order.root,
                icon: ICONS.orderList
              },
              {
                title: t('order-create'),
                path: paths.order.new,
                icon: ICONS.orderNew
              }
            ]
          }
        ]
      },
      // Catalog
      {
        subheader: t('catalog'),
        icon: ICONS.catalog,
        items: [
          {
            title: t('catalog-management'),
            path: paths.catalog.catalogManagement.root,
            icon: ICONS.catalogManagement,
            children: [
              {
                title: t('catalog-list'),
                path: paths.catalog.catalogManagement.root,
                icon: ICONS.catalogList
              },
              {
                title: t('catalog-new'),
                path: paths.catalog.catalogManagement.create,
                icon: ICONS.catalogNew
              }
            ]
          }
        ]
      },
      // Management
      {
        subheader: t('management'),
        icon: ICONS.management,
        items: [
          {
            title: t('customer-management'),
            path: paths.customer.root,
            icon: ICONS.customerManagement,
            children: [
              {
                title: t('customer-list'),
                path: paths.customer.customerManagement.root,
                icon: ICONS.customerList
              },
              {
                title: t('customer-new'),
                path: paths.customer.customerManagement.create,
                icon: ICONS.customerNew
              }
            ]
          },
          {
            title: t('user-management'),
            path: paths.user.root,
            icon: ICONS.userManagement,
            children: [
              {
                title: t('user-list'),
                path: paths.user.list,
                icon: ICONS.userList
              },
              {
                title: t('user-new'),
                path: paths.user.new,
                icon: ICONS.userNew
              }
            ]
          }
        ]
      }
    ],
    [t]
  );

  return data;
}
