import { useState, useEffect } from 'react';
import axios, { endpoints } from 'src/utils/axios';

export const useConstants = () => {
  const [constants, setConstants] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchConstants = async () => {
    try {
      const response = await axios.get(endpoints.inventory.constants);
      setConstants(response.data);
      setError(null);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching constants:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConstants();
  }, []);

  return { constants, loading, error, refetch: fetchConstants };
};
