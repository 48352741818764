// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Button from '@mui/material/Button';
// theme
import { paper } from 'src/theme/css';
//
import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar';
//
import { useSettingsContext } from '../context';
import { useAppConstants } from 'src/contexts/ConstantsContext';
import BaseOptions from './base-option';
import PresetsOptions from './presets-options';
import StretchOptions from './stretch-options';

export default function SettingsDrawer() {
  const theme = useTheme();
  const settings = useSettingsContext();
  const { refetch } = useAppConstants();

  const labelStyles = {
    mb: 1.5,
    color: 'text.disabled',
    fontWeight: 'fontWeightSemiBold'
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Ayarlar
      </Typography>

      <Tooltip title="Sıfırla">
        <IconButton onClick={settings.onReset}>
          <Badge color="error" variant="dot" invisible={!settings.canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip>

      <IconButton onClick={settings.onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  // const renderContrast = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Kontrast
  //     </Typography>

  //     <BaseOptions
  //       value={settings.themeContrast}
  //       onChange={(newValue) => settings.onUpdate('themeContrast', newValue)}
  //       options={['default', 'bold']}
  //       icons={['contrast', 'contrast_bold']}
  //     />
  //   </div>
  // );

  // const renderDirection = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Yazı Yönü
  //     </Typography>

  //     <BaseOptions
  //       value={settings.themeDirection}
  //       onChange={(newValue) => settings.onUpdate('themeDirection', newValue)}
  //       options={['ltr', 'rtl']}
  //       icons={['align_left', 'align_right']}
  //     />
  //   </div>
  //);

  // const renderStretch = (
  //   <div>
  //     <Typography
  //       variant="caption"
  //       component="div"
  //       sx={{
  //         ...labelStyles,
  //         display: 'inline-flex',
  //         alignItems: 'center'
  //       }}
  //     >
  //       Genişletme
  //       <Tooltip title="Sadece yüksek çözünürlükteki ekranlar için > 1600px (xl)">
  //         <Iconify icon="eva:info-outline" width={16} sx={{ ml: 0.5 }} />
  //       </Tooltip>
  //     </Typography>

  //     <StretchOptions
  //       value={settings.themeStretch}
  //       onChange={() => settings.onUpdate('themeStretch', !settings.themeStretch)}
  //     />
  //   </div>
  // );

  const renderPresets = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Renk Seçenekleri
      </Typography>

      <PresetsOptions
        value={settings.themeColorPresets}
        onChange={(newValue) => settings.onUpdate('themeColorPresets', newValue)}
      />
    </div>
  );

  const renderConstantsRefresh = () => (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Sistem Sabitleri
      </Typography>

      <Button
        fullWidth
        variant="outlined"
        onClick={refetch}
        startIcon={<Iconify icon="mdi:refresh" />}
        sx={{ mt: 1 }}
      >
        Sabitleri Güncelle
      </Button>
    </div>
  );

  return (
    <Drawer
      anchor="right"
      open={settings.open}
      onClose={settings.onClose}
      slotProps={{
        backdrop: { invisible: true }
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 280
        }
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          {/* {renderContrast}

          {renderDirection}

           */}
          {/* {renderStretch} */}
          {renderPresets}

          <Divider sx={{ borderStyle: 'dashed' }} />

          {renderConstantsRefresh()}
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
